export function whenReady(fn: () => void): void {
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", fn);
    } else {
        fn();
    }
}

export function toggleClassOnClick(className: string, clickable: HTMLElement, target: HTMLElement): void {
    clickable.addEventListener('click', (evt) => {
        evt.preventDefault();
        target.classList.toggle(className)
    });
}

whenReady(() => {
    toggleClassOnClick('active', document.getElementById('navbar-toggle'), document.getElementById('navbar-menu'));
});
